import { React, useEffect, useState } from "react";
import useStore from "../../store/useStore";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "../matchtimePc/MatchSchedulePc.css";
import giphy from "../../../assets/image/giphy.gif";

const PartnerLiveAtTopPc = ({ onData, onScore }) => {
  const [extraData, setExtraData] = useState(null);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [visibleMatches, setVisibleMatches] = useState(6);
  const navigate = useNavigate();
  const setVideoList = useStore((state) => state.setVideoList);
  const arrayScore = []
  const statusMapping = {
    1: "NO",
    2: "H1",
    3: "HT",
    4: "H2",
    5: "HP",
    6: "HP",
    7: "PEN",
    8: "END",
    9: "STOP",
    10: "STOP",
    11: "CUT",
    12: "CANCEL",
    13: "VS",
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const extractTime = (dateTimeString) => {
    return dateTimeString.split(" ")[1] || "";
  };
  const handleViewNow = (roomId) => {
    window.open(`/rooms/${roomId}`);
  };
  const fetchExtraData = async () => {
    try {
      const response = await fetch("https://spapi.zetcdn.site/livedata.json");
      if (!response.ok) {
        throw new Error("Error fetching extra data: " + response.statusText);
      }
      const extraData = await response.json();
      setExtraData(extraData);
    } catch (error) {
      console.error("Problem with extra data fetch:", error);
    }
  };
  const fetchData = async () => {
    const url = `${process.env.REACT_APP_API_URL}/api/rooms/partnerlive`;
    try {
      const response = await fetch(url, { method: "GET" });
      if (!response.ok) {
        throw new Error(
          "CHƯA CÓ TRẬN ĐẤU NÀO CỦA KÊNH NHÀ ĐÀI" + response.statusText
        );
      }
      const data = await response.json();
      setData(data);
      setVideoList(data);
      onData(data)
    } catch (error) {
      setError(error.message);
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchExtraData();
    const handleResize = () => {

      setVisibleMatches(6);

    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (error) return <div className="text-center mb-2">{error}</div>;

  if (data && extraData) {
    return (
      <div className="matchSchedule_Pc_main">
        {data
          .slice(0, visibleMatches)
          .map((match, index) => {
            const matchingExtraData = extraData.find(
              (item) => item.matchId === match.matchId
            );
            const score = matchingExtraData
              ? matchingExtraData.score.replace(",", " - ")
              : "0 - 0";
            const statusText = matchingExtraData
              ? statusMapping[matchingExtraData.status] : "VS";
            if (arrayScore.length < 1) {
              arrayScore.push(score)
              onScore(arrayScore)
            }
            return (
              <Link
                to={`/videopartner/${match.matchId}`}
                style={{ textDecoration: "none", color: "white" }}
                key={index}
              >
                <div className="match_schedule_Pc d-flex align-items-center justify-content-around">
                  <div className="match_league_Pc">
                    <div className='league_text'>{truncateText(match.league_title, 15)}</div>
                    <div className='league_text me-3'>
                      <img src={giphy} alt="" width="40%" />
                    </div>
                    <div className="match_live_symbol">
                      <div className="ms-1">{extractTime(match.startTime)}</div>
                    </div>
                  </div>
                  <div className="match_name_Pc">
                    <div className="match_team_one_Pc">
                      <div style={{ backgroundColor: 'white', borderRadius: '50%', padding: '2px', overflow: 'hidden' }}>
                        <img
                          src={match.localteam_logo}
                          width="35px"
                          height="35px"
                          alt={`${match.homeTeam} logo`}
                        />
                      </div>
                      <p>{truncateText(match.localteam_title, 7)}</p>
                    </div>
                    <div className="match-middle-content">
                      <div className="match-time">
                        {statusText}
                      </div>
                      <div className="match-vs"> {score}</div>
                    </div>
                    <div className="match_team_two_Pc">
                      <div style={{ backgroundColor: 'white', borderRadius: '50%', padding: '2px', overflow: 'hidden' }}>
                        <img
                          src={match.visitorteam_logo}
                          width="35px"
                          height="35px"
                          alt={`${match.visitorteam_title} logo`}
                          className=""
                        />
                      </div>
                      <p>{truncateText(match.visitorteam_title, 7)}</p>
                    </div>
                  </div>

                  <div className="match_time_Pc_button">
                    <button onClick={() => handleViewNow(match.matchId)}>
                      XEM NGAY
                      <i className="fa-regular fa-circle-play ms-1"></i>
                    </button>
                    {/* <button>NHÀ ĐÀI</button> */}
                  </div>
                </div>
              </Link>
            );
          })}

      </div>
    );
  } else {
    return <div className="text-center">...LOADING KÊNH NHÀ ĐÀI</div>;
  }
};

export default PartnerLiveAtTopPc;
