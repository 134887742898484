import React, { useEffect, useState ,useRef } from "react";
import "./LiveStream.css";
import { useParams ,useNavigate} from "react-router-dom";
import VideoLive from "../home/Video/VideoLive";
import Infomatch from "./infomatch/Infomatch";
import speaker from "../../assets/image/speaker.webp";
import Topnhacai from "./othermatch/Topnhacai";
import PartnerLivePc from "../hompagePc/partnerlive/PartnerLivePc";
import homeIcon from "../../assets/image/home.webp";
import logo from "../../assets/image/logo-header.webp";

const LiveStream = () => {
  const navigate = useNavigate();
  const { roomId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);
  const jwtToken = localStorage.getItem("accessToken");
  const [dataLive, setDataLive] = useState(null);
  const [heighIframe, setheighIframe] = useState(0);
  const videoMatchRef = useRef(null);
  const iframeVideoRef = useRef(null);
  const midliveVideoRef = useRef(null);
  

  const [videoMatchHeight, setVideoMatchHeight] = useState(0);
  const [iframeVideoHeight, setIframeVideoHeight] = useState(0);
  const [midliveVideoHeight, setmidliveVideoRef] = useState(0);
  
  useEffect(() => {
    const fetchRoomData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/rooms/${roomId}`
        );

        if (!response.ok) {
          navigate('/login');
          throw new Error("Failed to fetch room data");
        }
        
        const message = await response.json();
        if (
          message.message ===
          "Truy Cập Room Thành Công, Có thể Emit để connect room socket"
        ) {
          setData(message.room.cdnlink);
          setDataLive(message.room);
        } else {
          throw new Error("Failed to connect to the live room");
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRoomData();
  }, [roomId]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const observeHeights = () => {
      const videoMatchObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          setVideoMatchHeight(entry.contentRect.height);
        }
      });

      const iframeVideoObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          setIframeVideoHeight(entry.contentRect.height);
        }
      });

      const midliveVideoObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          setmidliveVideoRef(entry.contentRect.height);
        }
      });

      if (videoMatchRef.current) videoMatchObserver.observe(videoMatchRef.current);
      if (iframeVideoRef.current) iframeVideoObserver.observe(iframeVideoRef.current);
      if (midliveVideoRef.current) midliveVideoObserver.observe(midliveVideoRef.current);

      return () => {
        videoMatchObserver.disconnect();
        iframeVideoObserver.disconnect();
        midliveVideoObserver.disconnect();
      };
    };

    observeHeights();
  }, [data, dataLive, isMobile]);

  useEffect(() => {
    setheighIframe(window.innerHeight - videoMatchHeight - 47.5)
  }, [videoMatchHeight, iframeVideoHeight]);

  if (loading) {
    return <div>Loading...Livestream</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="live_main d-flex justify-content-center align-items-center">
      <div className="main_live" style={{ maxWidth: "82%" }}>
        <div className="main_live_padding">
          {!isMobile && (
            <div className="livestream-notification">
              <img src={speaker} alt="" />
              <div className="ln-text">
                <marquee>
                  🔥
                  <span style={{ color: "#ff0e0e", fontWeight: "600" }}>
                    HOT !
                  </span>
                  🔥 Siêu bão KM từ{" "}
                  <span style={{ color: "#fc8b00", fontWeight: "bolder" }}>
                    500AE TV
                  </span>{" "}
                  : Đăng ký tài khoản tặng{" "}
                  <span style={{ color: "#fc8b00", fontWeight: "bolder" }}>
                    57K
                  </span>
                  🎁 Thể Thao nạp đầu tặng{" "}
                  <span style={{ color: "#fc8b00", fontWeight: "bolder" }}>
                    8.888K
                  </span>{" "}
                  👉 CƯỢC CÀNG NHIỀU NHẬN THƯỞNG CÀNG CAO .☎️ Liên hệ ngay:{" "}
                  <span style={{ color: "#29e58a", fontWeight: "bolder" }}>
                    @TIP500AE
                  </span>{" "}
                  ☎️{" "}
                  <span style={{ color: "#29e58a", fontWeight: "bolder" }}>
                    0785.66.9999
                  </span>{" "}
                  để nhận những phần quà hấp dẫn!🎁🎁🎁
                </marquee>
              </div>
            </div>
          )}
          <div className="live_video">
            <div className="d-flex justify-content-between align-items-start main_video_live">
              <div className="video_match_live"  ref={videoMatchRef}>
                {data && <VideoLive data={data} />}
              </div>
             {isMobile ?   <div className="iframe_video" ref={iframeVideoRef} style={{height:heighIframe}} >
                {!isMobile && (
                  <div className="text-center kenh_chat mb-2">
                    <p className="m-0">KÊNH CHAT</p>
                  </div>
                )}
                {isMobile ? (
                  <>
                    <div className="midLive-container"  ref={midliveVideoRef}>
                      <div className="midLive-home">
                        <a href="/" style={{ textAlign: 'center' }}>
                          <img src={homeIcon} alt="" width="70%" /></a>
                      </div>
                      <div className="midLive-chat">TRÒ CHUYỆN</div>
                      <div className="midLive-logo">
                        <img src={logo} alt="" />
                      </div>
                      <div className="midLive-join">
                        <a href="https://f1681.biz/?id=810742306&currency=VND&type=2" className="blinking-color">THAM GIA NGAY</a>
                      </div>
                    </div>
                    <div className="midLive-chatRoom" style={{height:heighIframe - midliveVideoHeight }}>
                      <iframe
                        className=""
                        src={jwtToken ? `${process.env.REACT_APP_API_URL}/${roomId}?token=${jwtToken}` : `${process.env.REACT_APP_API_URL}/${roomId}`}
                        title="Live Stream"
                        style={{
                          width: "100%",

                          allowtransparency: "yes",
                          allow: "autoplay",
                          frameborder: "0",
                          marginheight: "0",
                          marginwidth: "0",
                          scrolling: "auto",
                          height:heighIframe - midliveVideoHeight 
                        }}
                      ></iframe>
                    </div>
                  </>
                ) : (
                  <iframe
                    className=""
                    src={jwtToken ? `${process.env.REACT_APP_API_URL}/${roomId}?token=${jwtToken}` : `${process.env.REACT_APP_API_URL}/${roomId}`}
                    title="Live Stream"
                    style={{
                      width: "100%",
                      allowtransparency: "yes",
                      allow: "autoplay",
                      frameborder: "0",
                      marginheight: "0",
                      marginwidth: "0",
                      scrolling: "auto",
                    }}
                  ></iframe>
                )}
              </div> :   <div className="iframe_video" ref={iframeVideoRef}  >
                {!isMobile && (
                  <div className="text-center kenh_chat mb-2">
                    <p className="m-0">KÊNH CHAT</p>
                  </div>
                )}
                {isMobile ? (
                  <>
                    <div className="midLive-container"  ref={midliveVideoRef}>
                      <div className="midLive-home">
                        <a href="/" style={{ textAlign: 'center' }}>
                          <img src={homeIcon} alt="" width="70%" /></a>
                      </div>
                      <div className="midLive-chat">TRÒ CHUYỆN</div>
                      <div className="midLive-logo">
                        <img src={logo} alt="" />
                      </div>
                      <div className="midLive-join">
                        <a href="https://f1681.biz/?id=810742306&currency=VND&type=2" className="blinking-color">THAM GIA NGAY</a>
                      </div>
                    </div>
                    <div className="midLive-chatRoom" style={{height:heighIframe - midliveVideoHeight }}>
                      <iframe
                        className=""
                        src={jwtToken ? `${process.env.REACT_APP_API_URL}/${roomId}?token=${jwtToken}` : `${process.env.REACT_APP_API_URL}/${roomId}`}
                        title="Live Stream"
                        style={{
                          width: "100%",
                          allowtransparency: "yes",
                          allow: "autoplay",
                          frameborder: "0",
                          marginheight: "0",
                          marginwidth: "0",
                          scrolling: "auto",
                          height:heighIframe - midliveVideoHeight 
                        }}
                      ></iframe>
                    </div>
                  </>
                ) : (
                  <iframe
                    className=""
                    src={jwtToken ? `${process.env.REACT_APP_API_URL}/${roomId}?token=${jwtToken}` : `${process.env.REACT_APP_API_URL}/${roomId}`}
                    title="Live Stream"
                    style={{
                      width: "100%",
                      allowtransparency: "yes",
                      allow: "autoplay",
                      frameborder: "0",
                      marginheight: "0",
                      marginwidth: "0",
                      scrolling: "auto",
                    }}
                  ></iframe>
                )}
              </div>}
            </div>
          </div>
          {!isMobile && dataLive && (
            <>
              <Infomatch dataLive={dataLive}></Infomatch>
              <PartnerLivePc></PartnerLivePc>
              <Topnhacai></Topnhacai>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LiveStream;
