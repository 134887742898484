import React from "react";
import "./Banner.css";
import thamgiacuoc from "../../../assets/image/thamgiacuoc.webp";
import thoadamme from "../../../assets/image/thoadamme.webp";
import imgfootball from "../../../assets/image/imgfootball.png";
import ball1 from "../../../assets/image/ball1.png";
import ball2 from "../../../assets/image/ball2.png";
export default function Banner() {
  return (
    <div className=" ">
      <div className="banner_cuocngay d-flex justify-content-center align-items-center "  >
        <div className="text-center football-image-parent">
          <div className="ball2-parent">
            <img src={ball2} width='100%' className="ball2" alt="500ae banner" />
          </div>
          <img src={imgfootball} className="imgfootball" width="100%" alt="500ae-football-img" />
          <div className="ball1-parent">
            <img src={ball1} width='100%' className="ball1" alt="500ae-ball1" />
          </div>
        </div>
        <div className="text-center">
          <div style={{ width: '100%' }}>
            <a href="https://f1681.biz/?id=810742306&currency=VND&type=2" target="_blank"
              rel="noopener noreferrer">
              <img src={thamgiacuoc} alt="500ae-tham-gia-cuoc" width="100%" className="thamgiacuoc" />
            </a>
          </div>
          <div style={{ width: '100%' }}>
            <img src={thoadamme} alt="500ae-thoa-dam-me" width='100%' className="thoadamme" />
          </div>
        </div>
      </div>
    </div>
  );
}
