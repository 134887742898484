import React, { useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';
import footballvideo from '../../../../assets/video/football.mp4';
import speaker from '../../../../assets/image/speaker.webp'
import Topnhacai from '../../../live/othermatch/Topnhacai'
import InfoMatchPartner from '../../../live/infomatchPartner/InfoMatchPartner'
import homeIcon from "../../../../assets/image/home.webp";
import logo from "../../../../assets/image/logo-header.webp";
import useStore from '../../../store/useStore';
import PartnerLivePc from '../../../hompagePc/partnerlive/PartnerLivePc';
import '../../../live/LiveStream.css'
const jwtToken = localStorage.getItem('accessToken');

const VideoPartnerLive = () => {
  const videoList = useStore((state) => state.videoList);
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const path = window.location.pathname;
  const matchId = path.split('/').pop();

  const [heighIframe, setheighIframe] = useState(0);
  const videoMatchRef = useRef(null);
  const iframeVideoRef = useRef(null);
  const midliveVideoRef = useRef(null);

  const [videoMatchHeight, setVideoMatchHeight] = useState(0);
  const [iframeVideoHeight, setIframeVideoHeight] = useState(0);
  const [midliveVideoHeight, setmidliveVideoRef] = useState(0);

  const filteredVideo = videoList.find(video => video.matchId === matchId);
  const m3u8Url = filteredVideo ? filteredVideo.m3u8 : null;

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);

  // Handle video interaction (e.g., play on click)
  const handleUserInteraction = () => {
    if (!isPlaying && videoRef.current) {
      videoRef.current.muted = false; // Unmute the video
      videoRef.current.play(); // Start playing
      setIsPlaying(true); // Update the playing state
    }
  };

  useEffect(() => {
    const video = videoRef.current;
    if (m3u8Url && video) {
      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(m3u8Url);
        hls.attachMedia(video);

        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          video.muted = true;
          video.play().catch(() => { });
        });

        return () => hls.destroy();
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = m3u8Url;
        video.muted = true;
        video.play().catch(() => { });
      } else {
        video.src = footballvideo;
        video.muted = true;
        video.play().catch(() => { });
      }
    }
  }, [m3u8Url]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const observeHeights = () => {
      const videoMatchObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          setVideoMatchHeight(entry.contentRect.height);
        }
      });

      const iframeVideoObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          setIframeVideoHeight(entry.contentRect.height);
        }
      });

      const midliveVideoObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          setmidliveVideoRef(entry.contentRect.height);
        }
      });

      if (videoMatchRef.current) videoMatchObserver.observe(videoMatchRef.current);
      if (iframeVideoRef.current) iframeVideoObserver.observe(iframeVideoRef.current);
      if (midliveVideoRef.current) midliveVideoObserver.observe(midliveVideoRef.current);

      return () => {
        videoMatchObserver.disconnect();
        iframeVideoObserver.disconnect();
        midliveVideoObserver.disconnect();
      };
    };

    observeHeights();
  }, [isMobile]);

  useEffect(() => {
    setheighIframe(window.innerHeight - videoMatchHeight - 47.5)
  }, [videoMatchHeight, iframeVideoHeight]);

  return (
    <div className="live_main d-flex justify-content-center align-items-center">
      <div className="main_live" style={{ maxWidth: "82%" }}>
        <div className="main_live_padding">
          {!isMobile && (
            <div className="livestream-notification">
              <img src={speaker} alt="" />
              <div className="ln-text">
                <marquee>
                  🔥
                  <span style={{ color: "#ff0e0e", fontWeight: "600" }}>
                    HOT !
                  </span>
                  🔥 Siêu bão KM từ{" "}
                  <span style={{ color: "#fc8b00", fontWeight: "bolder" }}>
                    500AE TV
                  </span>{" "}
                  : Đăng ký tài khoản tặng{" "}
                  <span style={{ color: "#fc8b00", fontWeight: "bolder" }}>
                    57K
                  </span>
                  🎁 Thể Thao nạp đầu tặng{" "}
                  <span style={{ color: "#fc8b00", fontWeight: "bolder" }}>
                    8.888K
                  </span>{" "}
                  👉 CƯỢC CÀNG NHIỀU NHẬN THƯỞNG CÀNG CAO .☎️ Liên hệ ngay:{" "}
                  <span style={{ color: "#29e58a", fontWeight: "bolder" }}>
                    @TIP500AE
                  </span>{" "}
                  ☎️{" "}
                  <span style={{ color: "#29e58a", fontWeight: "bolder" }}>
                    0785.66.9999
                  </span>{" "}
                  để nhận những phần quà hấp dẫn!🎁🎁🎁
                </marquee>
              </div>
            </div>
          )}
          <div className="live_video">
            <div className="d-flex justify-content-between align-items-start main_video_live">
              <div className="video_match_live" ref={videoMatchRef}>
                <div className="video_main">
                  <video
                    ref={videoRef}
                    controls
                    playsInline // Prevents fullscreen on iOS
                    webkit-playsinline="true" // Older versions of iOS Safari might require this
                    onClick={handleUserInteraction}
                    onPlay={() => setIsPlaying(true)}
                    onPause={() => setIsPlaying(false)}
                    style={{ backgroundColor: 'black' }}>
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
              {
                isMobile ? <div className="iframe_video" ref={iframeVideoRef} style={{ height: heighIframe }}>
                  {!isMobile && (
                    <div className="text-center kenh_chat mb-2">
                      <p className="m-0">KÊNH CHAT</p>
                    </div>
                  )}
                  {isMobile ? (
                    <>
                      <div className="midLive-container" ref={midliveVideoRef}>
                        <div className="midLive-home">
                          <a href="/">
                            <img src={homeIcon} alt="" /></a>
                        </div>
                        <div className="midLive-chat">TRÒ CHUYỆN</div>
                        <div className="midLive-logo">
                          <img src={logo} alt="" />
                        </div>
                        <div className="midLive-join">
                          <a href="https://f1681.biz/?id=810742306&currency=VND&type=2" className="blinking-color">THAM GIA NGAY</a>
                        </div>
                      </div>
                      <div className="midLive-chatRoom">
                        <iframe
                          src={jwtToken ? `${process.env.REACT_APP_API_URL}/66e2d08c51acae5963c0cdd9?token=${jwtToken}` : `${process.env.REACT_APP_API_URL}/66e2d08c51acae5963c0cdd9`}
                          title="Live Stream"
                          style={{
                            width: "100%",
                            allowtransparency: "yes",
                            allow: "autoplay",
                            frameborder: "0",
                            marginheight: "0",
                            marginwidth: "0",
                            scrolling: "auto",
                            height: heighIframe - midliveVideoHeight
                          }}
                        ></iframe>
                      </div>
                    </>
                  ) : (
                    <iframe
                      src={jwtToken ? `${process.env.REACT_APP_API_URL}/66e2d08c51acae5963c0cdd9?token=${jwtToken}` : `${process.env.REACT_APP_API_URL}/66e2d08c51acae5963c0cdd9`}
                      title="Live Stream"
                      style={{
                        width: "100%",
                        allowtransparency: "yes",
                        allow: "autoplay",
                        frameborder: "0",
                        marginheight: "0",
                        marginwidth: "0",
                        scrolling: "auto",
                      }}
                    ></iframe>
                  )}
                </div> : <div className="iframe_video" ref={iframeVideoRef}>
                  {!isMobile && (
                    <div className="text-center kenh_chat mb-2">
                      <p className="m-0">KÊNH CHAT</p>
                    </div>
                  )}
                  {isMobile ? (
                    <>
                      <div className="midLive-container" ref={midliveVideoRef}>
                        <div className="midLive-home">
                          <a href="/">
                            <img src={homeIcon} alt="" /></a>
                        </div>
                        <div className="midLive-chat">TRÒ CHUYỆN</div>
                        <div className="midLive-logo">
                          <img src={logo} alt="" />
                        </div>
                        <div className="midLive-join">
                          <a href="https://f1681.biz/?id=810742306&currency=VND&type=2" className="blinking-color">THAM GIA NGAY</a>
                        </div>
                      </div>
                      <div className="midLive-chatRoom">
                        <iframe
                          src={jwtToken ? `${process.env.REACT_APP_API_URL}/66e2d08c51acae5963c0cdd9?token=${jwtToken}` : `${process.env.REACT_APP_API_URL}/66e2d08c51acae5963c0cdd9`}
                          title="Live Stream"
                          style={{
                            width: "100%",
                            allowtransparency: "yes",
                            allow: "autoplay",
                            frameborder: "0",
                            marginheight: "0",
                            marginwidth: "0",
                            scrolling: "auto",
                          }}
                        ></iframe>
                      </div>
                    </>
                  ) : (
                    <iframe
                      src={jwtToken ? `${process.env.REACT_APP_API_URL}/66e2d08c51acae5963c0cdd9?token=${jwtToken}` : `${process.env.REACT_APP_API_URL}/66e2d08c51acae5963c0cdd9`}
                      title="Live Stream"
                      style={{
                        width: "100%",
                        allowtransparency: "yes",
                        allow: "autoplay",
                        frameborder: "0",
                        marginheight: "0",
                        marginwidth: "0",
                        scrolling: "auto",
                      }}
                    ></iframe>
                  )}
                </div>
              }
            </div>
          </div>
          {!isMobile && filteredVideo && (
            <>
              <InfoMatchPartner dataLive={filteredVideo} />
              <PartnerLivePc />
              <Topnhacai />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoPartnerLive;
