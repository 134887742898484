import React, { useEffect, useState } from "react";
import useStore from "../../store/useStore";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "./PartnerLivePc.css";
import trandaukhac from "../../../assets/image/trandaukhacpc.webp";
import giphy from "../../../assets/image/giphy.gif";


const PartnerLivePc = (dataSlide) => {
  const [data, setData] = useState(null);
  const [extraData, setExtraData] = useState(null);
  const [error, setError] = useState(null);
  const setVideoList = useStore((state) => state.setVideoList);

  const truncateText = (text, maxLength) => {
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

  const statusMapping = {
    1: "NO",
    2: "H1",
    3: "HT",
    4: "H2",
    5: "HP",
    6: "HP",
    7: "PEN",
    8: "END",
    9: "STOP",
    10: "STOP",
    11: "CUT",
    12: "CANCEL",
    13: "VS",
  };

  const extractTime = (dateTimeString) => {
    return dateTimeString.split(" ")[1] || "";
  };

  const fetchData = async () => {
    const url = `${process.env.REACT_APP_API_URL}/api/rooms/partnerlive`;
    try {
      const response = await fetch(url, { method: "GET" });
      if (!response.ok) {
        throw new Error(
          "CHƯA CÓ TRẬN ĐẤU NÀO CỦA KÊNH NHÀ ĐÀI" + response.statusText
        );
      }
      const data = await response.json();
      setData(data);
      setVideoList(data);
    } catch (error) {
      setError(error.message);
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const fetchExtraData = async () => {
    try {
      const response = await fetch("https://spapi.zetcdn.site/livedata.json");
      if (!response.ok) {
        throw new Error("Error fetching extra data: " + response.statusText);
      }
      const extraData = await response.json();
      setExtraData(extraData);
    } catch (error) {
      console.error("Problem with extra data fetch:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchExtraData();
  }, []);

  if (error) {
    return <div className="text-center">{error}</div>;
  }

  if (data && extraData) {
    return (
      <div className="">
        <div className="main_carousel" >
          <div className="carousel-container mb-2">
            <img src={trandaukhac} alt="" width="100%" />
            <div className="responsive-carousel" style={{ width: "92%" }}>
              <Swiper
                modules={[Navigation, Pagination, Autoplay]}
                spaceBetween={30}
                navigation
                pagination={{ clickable: true }}
                breakpoints={{
                  1300: { slidesPerView: 4 },
                  0: { slidesPerView: 3 },
                }}
                className="pb-5"
              >
                {data.map((match) => {
                  const matchingExtraData = extraData.find(
                    (item) => item.matchId === match.matchId
                  );
                  const score = matchingExtraData
                    ? matchingExtraData.score.replace(",", " - ")
                    : "0 - 0";
                  const statusText = matchingExtraData
                    ? statusMapping[matchingExtraData.status] : "VS";
                  return (
                    <SwiperSlide key={match.matchId}>
                      <div className="match-card text-center">
                        <div className="match_league_Pc">
                          {(
                            <div className="league_text">
                              {truncateText(match.league_title, 20)}
                            </div>
                          )}
                          <div className='league_text'>
                            <img src={giphy} alt="" width="40%" />
                          </div>
                          <div className="match_live_symbol">
                            <div className="">

                              <p style={{ fontSize: "0.5vw", margin: 0 }}>
                                {extractTime(match.startTime)}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="PLPC-match-container teams d-flex justify-content-around align-items-center text-center px-3">
                          <div className="text-center" >
                            <img style={{ backgroundColor: 'white', padding: '2px', borderRadius: '50%' }}
                              src={match.localteam_logo}
                              className="imglogo_partnerPc"
                              width="39px"
                              height="39px"
                              alt={match.localteam_title}
                            />
                            <p className="m-0 mt-2">
                              {truncateText(match.localteam_title, 9)}
                            </p>
                          </div>
                          <div className="PLPC-match-info">
                            <p className="PLPC-time m-0">{statusText}</p>
                            <p className="PLPC-vs-text m-0">{score}</p>
                          </div>
                          <div className="text-center">
                            <img style={{ backgroundColor: 'white', padding: '2px', borderRadius: '50%' }}
                              src={match.visitorteam_logo}
                              className="imglogo_partnerPc"
                              width="39px"
                              height="39px"
                              alt={match.visitorteam_title}
                            />
                            <p className="m-0 mt-2">
                              {truncateText(match.visitorteam_title, 9)}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center mt-3">
                          <div>
                            <a
                              className="watch-button text-light text-decoration-none"
                              href={`/videopartner/${match.matchId}`}
                            >
                              Xem ngay{" "}
                              <i className="fa-regular fa-circle-play ms-1"></i>
                            </a>
                          </div>

                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="text-center">
        <p>...LOADING KÊNH NHÀ ĐÀI</p>
      </div>
    );
  }
};

export default PartnerLivePc;
