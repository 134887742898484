import React from "react";
import "./Community.css";
import imginnestaandtext from "../../../assets/image/imginnestaandtext.webp";
import comuImg1 from "../../../assets/image/comu-img-right1.webp";
import comuImg2 from "../../../assets/image/comu-img-right2.webp";
import comuImg3 from "../../../assets/image/comu-img-right3.webp";
import comuImg4 from "../../../assets/image/comu-img-right4.webp";

const Community = () => {
  return (
    <div className=" px-3 mt-3">
      {/* <div className="p-1">
        <img width="100%" src={imginnestaandtext} alt="" />
      </div> */}
      <div className="text-center main_text_community mt-2">
        <p>
          CỘNG ĐỒNG <span>ĐAM MÊ</span>{" "}
        </p>
      </div>
      <div className="comunity-container d-flex justify-content-between">
        <div className="community-one ">
          <a  href="https://t.me/TIP500AE"  target="_blank"
              rel="noopener noreferrer">
            <img alt="500ae-khuyen-mai-hot" src={comuImg1} width="100%" className="comuImg1" ></img>
          </a>
          <a href="https://www.facebook.com/500AESHBET/"  target="_blank"
              rel="noopener noreferrer">
            <img alt="500ae-du-doan-nhan-qua" src={comuImg3} width="100%" className="comuImg3"></img>
          </a>
        </div>
        <div className="community-two">
          <a href=" https://urlvn.net/dudoannhancd57k"  target="_blank"
              rel="noopener noreferrer">
            <img alt="500ae-keo-bong-fb" src={comuImg2} width="100%" className="comuImg2"></img>
          </a>
          <a href=" https://urlvn.net/dudoannhancd57k"  target="_blank"
              rel="noopener noreferrer">
            <img alt="500ae-group-keo-bong" src={comuImg4} width="100%" className="comuImg4"></img>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Community;
